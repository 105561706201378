<template>
  <w-dialog :border="false" closeFree width="950px" @ok="selectOk" title="请选择" v-model="visible">
    <div class="picker">
      <div class="candidate">
        <div style="text-align: center;font-size: 15px;font-weight: bold">系统角色</div>
        <el-tree style="height: 350px;overflow: auto;"  node-key="id" :data="orgs">
          <template #default="{ node,data }">
            <div style="display: flex;align-content:center">
              <el-checkbox style="margin-top: 2px" :disabled="data.disabled" v-model="data.selected" @change="selectChange(node)"></el-checkbox>
              <div style="margin-left: 10px">{{ data.name }}</div>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="selected">
        <div class="count">
          <span>已选 {{ select.length }} 项</span>
          <span style="color: red;font-size: 12px;" @click="clearSelected">清空</span>
        </div>
        <div class="org-items" style="height: 350px;">
          <el-empty :image-size="100" description="请点击左侧列表选择数据" v-show="select.length === 0"/>
          <div v-for="(org, index) in select" :key="index" :class="orgItemClass(org)">
            <div>
              <span class="name">{{ org.name }}</span>
            </div>
            <i class="el-icon-close" @click="noSelected(index)"></i>
          </div>
        </div>
      </div>
    </div>
  </w-dialog>
</template>

<script>
import {selectRole} from '@/api/contacts/role.js'

export default {
  name: "OrgPicker",
  components: {},
  props: {
    title: {
      default: '请选择',
      type: String
    },
    type: {
      default: 'org', //org选择部门/人员  user-选人  dept-选部门 role-选角色
      type: String
    },
    multiple: { //是否多选
      default: false,
      type: Boolean
    },
    selected: {
      default: () => {
        return []
      },
      type: Array
    },
  },
  data() {
    return {
      visible: false,
      checkAll: false,
      nowDeptId: null,
      isIndeterminate: false,
      searchUsers: [],
      nodes: [],
      select: [],
      search: '',
      deptStack: []
    }
  },
  computed: {
    deptStackStr() {
      return String(this.deptStack.map(v => v.name)).replaceAll(',', ' > ')
    },
    orgs() {
      return !this.search || this.search.trim() === '' ? this.nodes : this.searchUsers
    },
    showUsers(){
      return this.search || this.search.trim() !== ''
    }
  },
  methods: {
    orgItemClass(org){
      return {
        'org-item': true,
        'org-dept-item': org.type === 'dept',
        'org-user-item': org.type === 'user',
        'org-role-item': org.type === 'role'
      }
    },
    show() {
      this.visible = true
      this.init()
      this.getOrgList()
    },
    getOrgList() {
      selectRole().then(rsp => {
        this.nodes = rsp.data;
        this.echo(this.nodes);
      }).catch(err => {
        this.$message.error(err.response.data)
      })
    },
    getShortName(name) {
      if (name) {
        return name.length > 2 ? name.substring(1, 3) : name;
      }
      return '**'
    },
    noSelected(index) {
      let tree = this.nodes;
      let id = this.select[index].id;
      for (let p = 0; p < tree.length; p++) {
        for (let i = 0; i < tree[p].children.length; i++) {
          if (tree[p].children[i].id === id){
            tree[p].children[i].selected = false
          }
        }
      }
      this.select.splice(index, 1)
    },
    handleCheckAllChange() {
      this.nodes.forEach(node => {
        if (this.checkAll) {
          if (!node.selected) {
            node.selected = true
            this.select.push(node)
          }
        } else {
          node.selected = false;
          for (let i = 0; i < this.select.length; i++) {
            if (this.select[i].id === node.id) {
              this.select.splice(i, 1);
              break;
            }
          }
        }
      })
    },
    selectChange(){
      let list = [];
      let tree = this.nodes;
      for (let p = 0; p < tree.length; p++) {
        for (let i = 0; i < tree[p].children.length; i++) {
          if (tree[p].children[i].selected === true){
            list.push(tree[p].children[i]);
          }
        }
      }
      this.select = list;
    },
    nextNode(node) {
      this.nowDeptId = node.id
      this.deptStack.push(node)
      this.getOrgList()
    },
    beforeNode() {
      if (this.deptStack.length === 0) {
        return;
      }
      if (this.deptStack.length < 2) {
        this.nowDeptId = null
      } else {
        this.nowDeptId = this.deptStack[this.deptStack.length - 2].id
      }
      this.deptStack.splice(this.deptStack.length - 1, 1);
      this.getOrgList()
    },
    recover() {
      this.select = []
      this.nodes.forEach(nd => nd.selected = false)
    },
    selectOk() {
      this.$emit('ok', Object.assign([], this.select.map(v => {
        v.avatar = undefined
        return v
      })))
      this.visible = false
      this.recover()
    },
    clearSelected(){
      this.$confirm('您确定要清空已选中的项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.recover()
      })
    },
    close() {
      this.$emit('close')
      this.recover()
    },
    init() {
      this.checkAll = false;
      this.nowDeptId = null;
      this.deptStack = [];
      this.nodes = []
      this.select = Object.assign([], this.selected);
    },
    echo(){
      let tree = this.nodes;
      for (let p = 0; p < tree.length; p++) {
        for (let i = 0; i < tree[p].children.length; i++) {
          this.select.forEach(function(item) {
            if (item.id === tree[p].children[i].id) {
              tree[p].children[i].selected = true;
            }
          });
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

@containWidth: 350px;
.picker {
  height: 500px;
  margin: 20px 10px 30px 10px;
  display: flex;
  justify-content: space-around;
  .candidate {
    padding: 16px 20px;
    box-shadow: 0px 1px 18px 1px #f5f5f5;
    border-radius: 20px;
    width: 35%;
    border: none;
    .role-header{
      padding: 10px !important;
      margin-bottom: 5px;
    }
    .top-dept{
      margin-left: 20px;
      color:#38adff;
    }
    .next-dept {
      color: @theme-primary;
      cursor: pointer;
    }

    .next-dept-disable {
      color: #8c8c8c;
      cursor: not-allowed;
    }

    & > div:first-child {
      padding: 5px 10px;
    }

  }

  .selected {
    width: 35%;
  }

  .org-items {
    overflow-y: auto;
    height: 310px;

    .el-icon-close {
      position: absolute;
      right: 5px;
      cursor: pointer;
      font-size: larger;
    }
    .org-dept-item {
      padding: 10px 5px;

      & > div {
        display: inline-block;

        & > span:last-child {
          position: absolute;
          right: 5px;
        }
      }
    }

    .org-role-item {
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    /deep/ .org-user-item {
      display: flex;
      align-items: center;
      padding: 5px;

      & > div {
        display: inline-block;
      }

      .avatar {
        width: 35px;
        text-align: center;
        line-height: 35px;
        background: @theme-primary;
        color: white;
        border-radius: 50%;
      }
    }

    /deep/ .org-item {
      margin: 0 5px;
      border-radius: 5px;
      position: relative;

      .el-checkbox {
        margin-right: 10px;
      }

      .name {
        margin-left: 5px;
      }

      &:hover {
        background: #f1f1f1;
      }
    }
  }
}

.selected {
  border: none;
  padding: 8px 10px;
  box-shadow: 0px 1px 18px 1px #f5f5f5;
  border-radius: 20px;
  .count {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 5px;
    & > span:nth-child(2) {
      float: right;
      color: #c75450;
      cursor: pointer;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

.disabled{
  cursor: not-allowed !important;
  color: #8c8c8c !important;
}

::-webkit-scrollbar {
  float: right;
  width: 4px;
  height: 4px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #efefef;
}
</style>
